import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { ComponentsLayout, Goal, ModalMobile, theme } from '@hdcorner/ui-library';
import PageLayout from '../../components/PageLayout';
import moment from 'moment/moment';
import ModalRemoveGoal from '../../components/ModalRemoveGoal';
import {
  useDeletePressureGoalMutation,
  useGetFirstLogOfGoalQuery,
  useGetLatestLogQuery,
  useGetPressureGoalQuery,
  useUpdatePressureGoalMutation,
} from './queries/hypertensionQueries';
import { useIonRouter } from '@ionic/react';
import ModalAddEditGoal from '../../components/ModalAddEditGoal';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { goalProgressHypertension } from '../../utils/goalProgress';

const HypertensionGoal = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentSuccess, presentError } = useAlert();

  const [openEditGoal, setOpenEditGoal] = useState<boolean>(false);
  const [openRemoveGoal, setOpenRemoveGoal] = useState<boolean>(false);

  const [deletePressureGoal] = useDeletePressureGoalMutation();
  const [updatePressureGoal] = useUpdatePressureGoalMutation();

  const { data: pressureGoal, error: errorGoal } = useGetPressureGoalQuery();
  const { data: latestHypertensionLogs, error: errorLatestHypLog } =
    useGetLatestLogQuery();
  const { data: startingHypertensionLogs, error: errorFirstHypLog } =
    useGetFirstLogOfGoalQuery(
      {
        start:
          pressureGoal && pressureGoal.length > 0 ? pressureGoal[0].startDate : undefined,
      },
      {
        skip: !pressureGoal || pressureGoal.length === 0,
      },
    );

  useEffect(() => {
    if (errorGoal) presentError(t('errors.hypertension.errorGoalFetch'));
    if (errorFirstHypLog || errorLatestHypLog)
      presentError(t('errors.hypertension.errorLogFetch'));
  }, [errorGoal, errorFirstHypLog, errorLatestHypLog]);

  const latestHypertensionData = useMemo(() => {
    if (latestHypertensionLogs && latestHypertensionLogs.documents.length > 0) {
      const data = latestHypertensionLogs.documents[0];
      return {
        systolic: data ? data.systolic : '-',
        diastolic: data ? data.diastolic : '-',
      };
    }

    return { systolic: '-', diastolic: '-' };
  }, [latestHypertensionLogs]);

  const startingHypertensionData = useMemo(() => {
    if (startingHypertensionLogs && startingHypertensionLogs.documents.length > 0) {
      const data = startingHypertensionLogs.documents[0];
      return {
        systolic: data ? data.systolic : '-',
        diastolic: data ? data.diastolic : '-',
      };
    }

    return latestHypertensionData;
  }, [latestHypertensionData, startingHypertensionLogs]);

  const prepareGoalData = useMemo(() => {
    if (pressureGoal && pressureGoal.length > 0) {
      return {
        systolic: pressureGoal[0].goal.systolic,
        diastolic: pressureGoal[0].goal.diastolic,
      };
    }

    return { systolic: '', diastolic: '' };
  }, [pressureGoal]);

  const prepareProgressData = useMemo(() => {
    let progress = 0;
    if (latestHypertensionData && pressureGoal && startingHypertensionData) {
      if (
        latestHypertensionData.systolic === '-' ||
        latestHypertensionData.diastolic === '-' ||
        startingHypertensionData.systolic === '-' ||
        startingHypertensionData.diastolic === '-'
      ) {
        progress = 0;
      } else {
        const goal = pressureGoal[0]?.goal || { systolic: 0, diastolic: 0 };
        const latest = latestHypertensionData as { systolic: number; diastolic: number };
        const starting = startingHypertensionData as {
          systolic: number;
          diastolic: number;
        };
        progress = goalProgressHypertension(latest, goal, starting);
      }
    }
    return progress;
  }, [latestHypertensionData, pressureGoal, startingHypertensionData]);

  const prepareData = useMemo(() => {
    if (!pressureGoal || pressureGoal.length === 0) return [];

    return [
      {
        label: t('hypertension.labels.goalSystolic'),
        value: `${pressureGoal![0].goal.systolic} ${t('measurements.mmGH')}`,
      },
      {
        label: t('hypertension.labels.goalDiastolic'),
        value: `${pressureGoal![0].goal.diastolic} ${t('measurements.mmGH')}`,
        marginBottom: true,
      },
      {
        label: t('goals.startingDate'),
        value: moment(pressureGoal![0].startDate).format('MMM Do, YYYY'),
      },
      {
        label: t('goals.startingMeasurement'),
        value: `${startingHypertensionData.systolic} / ${
          startingHypertensionData.diastolic
        } ${t('measurements.mmGH')}`,
      },
      {
        label: t('goals.latestMeasurement'),
        value: `${latestHypertensionData.systolic} / ${
          latestHypertensionData.diastolic
        } ${t('measurements.mmGH')}`,
      },
      {
        label: t('goals.progress'),
        value: prepareProgressData ? prepareProgressData + '%' : '-',
      },
    ];
  }, [
    t,
    pressureGoal,
    prepareProgressData,
    latestHypertensionData.systolic,
    latestHypertensionData.diastolic,
    startingHypertensionData.systolic,
    startingHypertensionData.diastolic,
  ]);

  const handleRemove = () => {
    setOpenRemoveGoal(true);
  };

  const handleEdit = () => {
    setOpenEditGoal(true);
  };

  const handleSaveClick = async (newGoals: any) => {
    if (pressureGoal && pressureGoal.length > 0) {
      try {
        await updatePressureGoal({
          goalType: 'pressure',
          goal: { ...newGoals },
          _id: pressureGoal[0]._id,
          startDate: moment().toISOString(),
        });
        presentSuccess(t('errors.hypertension.successEditGoal'));
        setOpenEditGoal(false);
        router.push('/dashboard/hypertension');
      } catch (e) {
        setOpenEditGoal(false);
        presentError(t('errors.hypertension.errorEditGoal'));
      }
    }
  };

  const handleAcceptRemove = async () => {
    if (pressureGoal) {
      try {
        await deletePressureGoal({ _id: pressureGoal[0]._id })
          .unwrap()
          .then(() => presentSuccess(t('errors.hypertension.successDeleteGoal')));
        setOpenRemoveGoal(false);
        router.push('/dashboard/hypertension');
      } catch (e) {
        presentError(t('errors.hypertension.errorDeleteGoal'));
      }
    }
  };

  return (
    <PageLayout
      headerTitle={t('headingsTitles.goal')}
      defaultHref={'/dashboard/hypertension'}
      headerColor={theme.palette.primary.main}
    >
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(1)}>
        <Goal data={prepareData} handleRemove={handleRemove} handleEdit={handleEdit} />
      </ComponentsLayout>
      <ModalMobile open={openRemoveGoal} setOpen={setOpenRemoveGoal}>
        <ModalRemoveGoal
          handleRemoveClick={handleAcceptRemove}
          handleCancelClick={() => setOpenRemoveGoal(false)}
        />
      </ModalMobile>
      <ModalMobile open={openEditGoal} setOpen={setOpenEditGoal}>
        <ModalAddEditGoal
          goalType={'pressure'}
          goal={prepareGoalData}
          handleSaveClick={handleSaveClick}
          currentMeasurement={latestHypertensionData}
        />
      </ModalMobile>
    </PageLayout>
  );
};

export default HypertensionGoal;
