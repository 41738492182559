import React, { useEffect, useMemo, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import {
  ComponentsLayout,
  GoalDetailsCard,
  ModalMobile,
  RemoveEditButtonsContainer,
} from '@hdcorner/ui-library';
import ModalRemoveGoal from '../../components/ModalRemoveGoal';
import {
  useDeleteLipidGoalMutation,
  useEditLipidGoalMutation,
  useGetLipidFirstLogQuery,
  useGetLipidGoalQuery,
  useGetLipidLastLogQuery,
} from './queries/lipidQueries';
import { useIonRouter } from '@ionic/react';
import { useParams } from 'react-router';
import { Box } from '@mui/material';
import moment from 'moment';
import ModalAddEditGoal from '../../components/ModalAddEditGoal';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { goalProgress } from '../../utils/goalProgress';

const LipidGoalScreen = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentSuccess, presentError } = useAlert();
  const { type } = useParams<{ type: 'lpa' | 'hdl' | 'ldl' | 'trg' | 'chol' }>();

  const [openEditGoal, setOpenEditGoal] = useState<boolean>(false);
  const [openRemoveGoal, setOpenRemoveGoal] = useState<boolean>(false);

  const [editLipidGoal] = useEditLipidGoalMutation();
  const [deleteLipidGoalMutation] = useDeleteLipidGoalMutation();

  const { data: lastLog, error: lastLogError } = useGetLipidLastLogQuery();
  const { data: lipidGoal, error: lipidGoalError } = useGetLipidGoalQuery(
    { type },
    {
      skip: !type,
    },
  );
  const { data: firstLog, error: firstLogError } = useGetLipidFirstLogQuery(
    { startingDate: lipidGoal && lipidGoal.length > 0 ? lipidGoal[0].startDate : '' },
    { skip: !lipidGoal || lipidGoal.length === 0 },
  );

  useEffect(() => {
    if (lipidGoalError) {
      presentError(t('errors.lipid.errorFetchGoal'));
    }
    if (firstLogError || lastLogError) {
      presentError(t('errors.lipid.errorFetchLog'));
    }
  }, [firstLogError, lipidGoalError, lastLogError]);

  const prepareProgressData = useMemo(() => {
    let progress = 0;
    if (lastLog && lipidGoal && firstLog) {
      if (!lastLog || !firstLog) {
        progress = 0;
      } else {
        const goalData = lipidGoal[0];
        const latestLogData: any = lastLog?.documents[0];
        const startingLogData: any = firstLog?.documents[0];

        let newType;
        if (type === 'chol') newType = 'totalChol';
        else if (type === 'trg') newType = 'triglycerides';
        else newType = type;
        const latest = latestLogData ? latestLogData[newType] : 0;
        const starting = startingLogData ? startingLogData[newType] : 0;
        const goalMeasurement = goalData?.goal?.measurement || '0';
        const goal = parseInt(goalMeasurement);
        progress = goalProgress(latest, goal, starting);
      }
    }
    return progress;
  }, [firstLog, lastLog, lipidGoal, type]);

  const getGoal = useMemo(() => {
    if (!lipidGoal || lipidGoal.length === 0) return [];

    const goalData = lipidGoal[0];
    const latestLogData: any = lastLog?.documents[0];
    const startingLogData: any = firstLog?.documents[0];

    let newType;
    if (type === 'chol') newType = 'totalChol';
    else if (type === 'trg') newType = 'triglycerides';
    else newType = type;

    return [
      {
        label: t('headingsTitles.goal'),
        value: goalData.goal.measurement + ' ' + t('measurements.mgdl'),
      },
      {
        label: t('goals.start'),
        value: goalData?.startDate
          ? moment(goalData.startDate).format('MMM DD, YYYY')
          : '-',
      },
      {
        label: t('goals.startingMeasurement'),
        value:
          startingLogData && startingLogData[newType]
            ? startingLogData[newType] + ' ' + t('measurements.mgdl')
            : '-',
      },
      {
        label: t('goals.latestMeasurement'),
        value:
          latestLogData && latestLogData[newType]
            ? latestLogData[newType] + ' ' + t('measurements.mgdl')
            : '-',
      },
      {
        label: t('goals.progress'),
        value: prepareProgressData ? prepareProgressData + '%' : '-',
      },
    ];
  }, [lipidGoal, lastLog?.documents, firstLog?.documents, type, t, prepareProgressData]);

  const getLatestMeasurement = useMemo(() => {
    if (!lastLog || lastLog.documents.length === 0) return;
    const latestLogData: any = lastLog.documents[0];
    return latestLogData[type];
  }, [lastLog, type]);

  const getDesiredGoal = useMemo(() => {
    if (!lipidGoal || lipidGoal.length === 0) return;
    const lipidGoalData = lipidGoal[0];
    return lipidGoalData.goal.measurement;
  }, [lipidGoal]);

  const handleClickRemove = () => {
    if (!lipidGoal || lipidGoal.length === 0) return;

    const lipidGoalValue = lipidGoal[0];
    if (lipidGoalValue) {
      deleteLipidGoalMutation({ _id: lipidGoalValue._id })
        .unwrap()
        .then(() => {
          setOpenRemoveGoal(false);
          router.push('/dashboard/lipid');
          presentSuccess(t('errors.lipid.successGoalDelete'));
        })
        .catch(e => {
          setOpenRemoveGoal(false);
          presentError(t('errors.lipid.errorGoalDelete'));
        });
    }
  };

  const handleRemove = () => {
    setOpenRemoveGoal(true);
  };

  const handleEdit = () => {
    setOpenEditGoal(true);
  };

  const handleSaveClick = (desiredGoal: string) => {
    if (!getDesiredGoal) return;

    if (!lipidGoal || lipidGoal.length === 0) return;
    const lipidGoalValue = lipidGoal[0];

    editLipidGoal({
      goalType: type,
      _id: lipidGoalValue._id,
      goal: { measurement: desiredGoal },
      startDate: lipidGoalValue.startDate,
    })
      .unwrap()
      .then(() => {
        setOpenEditGoal(false);
        presentSuccess(t('errors.lipid.successGoalEdit'));
      })
      .catch(() => presentError(t('errors.lipid.errorGoalEdit')));
  };

  const renderPlaceholder = useMemo(() => {
    if (type === 'lpa') return `14 ${t('measurements.mgdl')}`;
    if (type === 'hdl') return `40 ${t('measurements.mgdl')}`;
    if (type === 'ldl') return `100 ${t('measurements.mgdl')}`;
    if (type === 'trg') return `150 ${t('measurements.mgdl')}`;
    if (type === 'chol') return `200 ${t('measurements.mgdl')}`;
  }, [type]);

  return (
    <PageLayout headerTitle={t('headingsTitles.goal')} defaultHref={'/dashboard/lipid'}>
      <ComponentsLayout>
        {getGoal.map((item, index) => (
          <Box key={`${item}${index}`} mt={index === 2 ? 3 : 0} py={1}>
            <GoalDetailsCard goal={item} />
          </Box>
        ))}
        <RemoveEditButtonsContainer onRemove={handleRemove} onEdit={handleEdit} />
      </ComponentsLayout>
      <ModalMobile open={openRemoveGoal} setOpen={setOpenRemoveGoal}>
        <ModalRemoveGoal
          handleRemoveClick={handleClickRemove}
          handleCancelClick={() => setOpenRemoveGoal(false)}
        />
      </ModalMobile>
      <ModalMobile open={openEditGoal} setOpen={setOpenEditGoal}>
        <ModalAddEditGoal
          goalType={'lipid'}
          goal={getDesiredGoal}
          handleSaveClick={handleSaveClick}
          desiredPlaceholder={renderPlaceholder}
          currentMeasurement={getLatestMeasurement}
        />
      </ModalMobile>
    </PageLayout>
  );
};

export default LipidGoalScreen;
