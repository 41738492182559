import { MyDataComponent } from '@hdcorner/ui-library';
import { FC, useMemo } from 'react';
import { findUpdatedAt } from '../../../utils/dateTimeUtils';
import {
  useGetMedicationsValuesQuery,
  useGetRecordsValuesQuery,
  useGetTrendValuesQuery,
} from '../queries/dashboardQueries';
import moment from 'moment';
import { findCategoryLabel } from '../../records/utils';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/hooks';

type DashDataCardsProps = {
  handleClickCard: (value: string) => void;
};

const DashDataCards: FC<DashDataCardsProps> = ({ handleClickCard }) => {
  const { t } = useTranslation();

  const {
    data: { user },
  } = useAppSelector(state => state.auth);

  const premiumUser = useMemo(() => {
    if (!user || !user.premiumUser) return false;
    return user.premiumUser;
  }, [user]);

  const { data: trendsRes } = useGetTrendValuesQuery();
  const { data: recordsRes } = useGetRecordsValuesQuery();
  const { data: medicationRes } = useGetMedicationsValuesQuery(undefined, {
    skip: !premiumUser,
  });

  const fitnessData = useMemo(() => {
    const burned = trendsRes?.fitness.burned;
    const gained = trendsRes?.fitness.gained;
    const exercise = trendsRes?.fitness.exercise;
    const workouts = trendsRes?.fitness.workouts;

    const burnedDate = burned?.logDate ? moment(burned.logDate) : null;
    const gainedDate = gained?.logDate ? moment(gained.logDate) : null;
    const exerciseDate = exercise?.logDate ? moment(exercise.logDate) : null;

    let latestDate;

    if (!burnedDate && !gainedDate && !exerciseDate) {
      latestDate = null;
    } else {
      const validDates = [burnedDate, gainedDate, exerciseDate].filter(
        date => date,
      ) as moment.Moment[];
      const maxDate = moment.max(validDates);
      latestDate = maxDate.toISOString();
    }

    const timeStamp = findUpdatedAt(latestDate as string);

    return {
      premium: premiumUser,
      title: t('dashboard.dashcards.fitness.title'),
      twoColumn: true,
      date: `${t(timeStamp.translation)}${timeStamp.time}`,
      data: [
        {
          label: t('dashboard.dashcards.fitness.burned'),
          trend: burned?.trend || 'stable',
          info: `${burned?.measurement ? Math.round(burned.measurement) : '-'} ${t(
            'measurements.kcal',
          )}`,
        },
        {
          label: t('dashboard.dashcards.fitness.exercise'),
          trend: exercise?.trend || 'stable',
          info: `${exercise?.measurement ? Math.round(exercise.measurement) : '-'} ${t(
            'measurements.minutes',
          )}`,
        },
        {
          label: t('dashboard.dashcards.fitness.gained'),
          trend: gained?.trend || 'stable',
          info: `${gained?.measurement ? Math.round(gained.measurement) : '-'} ${t(
            'measurements.kcal',
          )}`,
        },
        {
          trend: workouts?.trend || 'stable',
          label: t('dashboard.dashcards.fitness.workouts'),
          info: workouts?.measurement ? workouts.measurement + '' : '-',
        },
      ],
    };
  }, [trendsRes, premiumUser, t]);

  const programsData = useMemo(() => {
    const latestProgram = trendsRes?.programs?.latestProgram;
    const weeklyTraining = trendsRes?.programs?.weeklyTraining;

    const timeStamp = latestProgram?.logDate
      ? findUpdatedAt(latestProgram?.logDate)
      : null;

    return {
      premium: premiumUser,
      title: t('dashboard.dashcards.programs.title'),
      date: timeStamp ? `${t(timeStamp.translation)}${timeStamp.time}` : '-',
      data: [
        {
          label: t('dashboard.dashcards.programs.latest'),
          info: latestProgram?.name ? latestProgram.name : '-',
        },
        {
          trend: weeklyTraining?.trend || 'stable',
          label: t('dashboard.dashcards.programs.weekly'),
          info: weeklyTraining?.measurement
            ? weeklyTraining.measurement + t('measurements.minutes')
            : '-',
        },
      ],
    };
  }, [premiumUser, trendsRes, t]);

  const hypertensionLogs = useMemo(() => {
    const pulses = trendsRes?.hypertension?.results?.pulses;
    const systolic = trendsRes?.hypertension?.results?.systolic;
    const diastolic = trendsRes?.hypertension?.results?.diastolic;

    const timeStamp = findUpdatedAt(pulses?.logDate);

    return {
      title: t('dashboard.dashcards.hypertension.title'),
      twoColumn: true,
      date: `${t(timeStamp.translation)}${timeStamp.time}`,
      data: [
        {
          trend: systolic?.trend,
          label: t('dashboard.dashcards.hypertension.systolic'),
          info: `${systolic?.measurement ? systolic.measurement : '-'} ${t(
            'measurements.mmGH',
          )}`,
        },
        {
          trend: diastolic?.trend,
          label: t('dashboard.dashcards.hypertension.diastolic'),
          info: `${diastolic?.measurement ? diastolic.measurement : '-'} ${t(
            'measurements.mmGH',
          )}`,
        },
        {
          label: t('dashboard.dashcards.hypertension.pulses'),
          trend: pulses?.trend,
          info: `${pulses?.measurement ? pulses.measurement : '-'} ${t(
            'measurements.bpm',
          )}`,
        },
      ],
    };
  }, [trendsRes, t]);

  const medicationData = useMemo(() => {
    const medicationsCount = medicationRes?.count;
    const latestMedication = medicationRes?.documents[0];

    const timeStamp = findUpdatedAt(latestMedication?.updatedAt);
    return {
      premium: premiumUser,
      title: t('dashboard.dashcards.medication.title'),
      date: `${t(timeStamp.translation)}${timeStamp.time}`,
      data: [
        {
          label: t('dashboard.dashcards.medication.medications'),
          info:
            `${medicationsCount ? medicationsCount : 0} ${t(
              'dashboard.dashcards.medication.medications',
            )}` || '',
        },
      ],
    };
  }, [medicationRes, premiumUser, t]);

  const lipidLogs = useMemo(() => {
    const ldl = trendsRes?.lipid?.results?.ldl;
    const hdl = trendsRes?.lipid?.results?.hdl;
    const lpa = trendsRes?.lipid?.results?.lpa;
    const totalChol = trendsRes?.lipid?.results?.totalChol;
    const triglycerides = trendsRes?.lipid?.results?.triglycerides;

    const timeStamp = findUpdatedAt(ldl?.logDate);

    return {
      twoColumn: true,
      title: t('dashboard.dashcards.lipid.title'),
      date: `${t(timeStamp.translation)}${timeStamp.time}`,
      data: [
        {
          trend: totalChol?.trend,
          label: t('dashboard.dashcards.lipid.chol'),
          info: `${totalChol?.measurement ? totalChol.measurement : '-'} ${t(
            'measurements.mgdl',
          )}`,
        },
        {
          trend: ldl?.trend,
          label: t('dashboard.dashcards.lipid.ldl'),
          info:
            `${ldl?.measurement ? ldl.measurement : '-'} ${t('measurements.mgdl')}` || '',
        },
        {
          trend: triglycerides?.trend,
          label: t('dashboard.dashcards.lipid.trg'),
          info: `${triglycerides?.measurement ? triglycerides.measurement : '-'} ${t(
            'measurements.mgdl',
          )}`,
        },
        {
          label: t('dashboard.dashcards.lipid.hdl'),
          info: `${hdl?.measurement ? hdl.measurement : '-'} ${t('measurements.mgdl')}`,
        },
        {
          label: t('dashboard.dashcards.lipid.lpa'),
          info: `${lpa?.measurement ? lpa.measurement : '-'} ${t('measurements.mgdl')}`,
        },
      ],
    };
  }, [trendsRes, t]);

  const bloodGlucoseLogs = useMemo(() => {
    let updatedAt;

    const hbac = trendsRes?.diabetes?.results?.hbac;
    const bloodGlucose = trendsRes?.diabetes?.results?.glucose;

    const hbacDate = hbac?.logDate ? moment(hbac.logDate) : null;
    const bloodGlucoseDate = bloodGlucose?.logDate ? moment(bloodGlucose.logDate) : null;

    if (hbacDate && bloodGlucoseDate) {
      updatedAt = hbacDate.isAfter(bloodGlucoseDate)
        ? hbacDate.toISOString()
        : bloodGlucoseDate.toISOString();
    }

    const timeStamp = findUpdatedAt(updatedAt);

    return {
      title: t('dashboard.dashcards.diabetes.title'),
      date: `${t(timeStamp.translation)}${timeStamp.time}`,
      data: [
        {
          label: t('dashboard.dashcards.diabetes.glucose'),
          trend: bloodGlucose?.trend,
          info: `${bloodGlucose?.measurement ? bloodGlucose.measurement : '-'} ${t(
            'measurements.mgdl',
          )}`,
        },
        {
          label: t('dashboard.dashcards.diabetes.hba1c'),
          trend: hbac?.trend,
          info: `${hbac?.measurement ? hbac.measurement : '-'} %`,
        },
      ],
    };
  }, [trendsRes, t]);

  const cvdData = useMemo(() => {
    const cvd = trendsRes?.cvd?.results?.cvd;

    const timeStamp = findUpdatedAt(cvd?.logDate);

    return {
      premium: premiumUser,
      title: t('dashboard.dashcards.cvd.title'),
      date: `${t(timeStamp.translation)}${timeStamp.time}`,
      data: [
        {
          label: t('dashboard.dashcards.cvd.overall'),
          trend: cvd?.trend ? cvd.trend : 'stable',
          info: `${cvd?.measurement ? cvd.measurement : '-'} %`,
        },
      ],
    };
  }, [trendsRes, premiumUser, t]);

  const healthData = useMemo(() => {
    const documents = recordsRes?.documents;
    const count = recordsRes?.count;

    const timeStamp = findUpdatedAt(documents?.[0]?.updatedAt);

    return {
      premium: premiumUser,
      title: t('dashboard.dashcards.health.title'),
      date: `${t(timeStamp.translation)}${timeStamp.time}`,
      data: [
        {
          label: t('dashboard.dashcards.health.files'),
          info: `${count}` || '',
        },
        {
          label: t('dashboard.dashcards.health.latest'),
          info: documents?.[0]?.category
            ? t(findCategoryLabel(documents[0].category))
            : '-',
        },
      ],
    };
  }, [recordsRes, t, premiumUser]);

  const data = useMemo<any>(() => {
    return [
      { fitness: fitnessData },
      { programs: programsData },
      { medication: medicationData },
      { lipid: lipidLogs },
      { hypertension: hypertensionLogs },
      { diabetes: bloodGlucoseLogs },
      { cvd: cvdData },
      { healthdata: healthData },
    ];
  }, [
    cvdData,
    lipidLogs,
    healthData,
    fitnessData,
    programsData,
    medicationData,
    hypertensionLogs,
    bloodGlucoseLogs,
    t,
  ]);

  return <MyDataComponent data={data} handleClickCard={handleClickCard} />;
};

export default DashDataCards;
